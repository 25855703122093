import React, { Component } from 'react'
import SwapForm from './SwapForm'
import Contracts from './Contracts'

import '../css/App.css';

// Main <App> component.

class App extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            isConnected: false,
            errorMessage: null,
            iceBalance:   0,
            h2oBalance:   0,
            stmBalance:   0,
            //h2oPrice:     0,
            icePrice:     0,
            steamPrice:   0,
            steamRate:    0,
            targetIcePrice: 0,
            icePoolSize:  0,
            icewaterPoolSize: 0,
            steamPoolSize: 0,
            steamwaterPoolSize: 0,
            claimableH2OFromICE:  0,
            claimableH2OFromSTEAM:  0,
            lastError: 0,
            accumulatedError: 0
        };

        this.connectWallet = this.connectWallet.bind(this);
        this.onSwap = this.onSwap.bind(this);
        this.onClaimICE = this.onClaimICE.bind(this);
        this.onClaimSTEAM = this.onClaimSTEAM.bind(this);
        this.setError = this.setError.bind(this);

        // Init the contracts
        this.contracts = new Contracts(this.setError);
    }

    async connectWallet(event) {
        event.preventDefault();

        // Load Web3 and the smart contracts
        if(await this.contracts.initWeb3()) {
            await this.contracts.load();
        }

        this.setState({ isConnected: this.contracts.isConnected() });
       
        if (this.contracts.isConnected()) {
            this.setState({ errorMessage: null });
            this.contracts.registerSwapEventHandler(this.onSwap);
        }        
        
        await this.updateState();
    }

    async onSwap(error, event) {
        if (error) {
            console.log(error);
            this.setError("While subscribing to event");
        } else {

            await this.updateState();
        }        
    }

    async onClaimICE(event) {
        event.preventDefault();
        await this.contracts.claimH2OFromICE();
        await this.updateState();
    }

    async onClaimSTEAM(event) {
        event.preventDefault();
        await this.contracts.claimH2OFromSteam();
        await this.updateState();
    }

    setError(message) {
        this.setState({ errorMessage: message });
    }

    async updateState() {
        if (this.contracts.isConnected()) {

            this.setState({
                iceBalance:   await this.contracts.getICEBalance(this.contracts.getAccountAddress()),
                h2oBalance:   await this.contracts.getH2OBalance(this.contracts.getAccountAddress()),
                stmBalance:   await this.contracts.getSTEAMBalance(this.contracts.getAccountAddress()),
                //h2oPrice:     await this.contracts.getH2OPrice(),
                icePrice:     await this.contracts.getIcePrice(),
                steamPrice:   await this.contracts.getSteamPrice(),
                steamRate:     await this.contracts.getCondensationRate(),
                targetIcePrice: await this.contracts.getTargetIcePrice(),
                icePoolSize:  await this.contracts.getIcePool(),
                icewaterPoolSize: await this.contracts.getIcewaterPool(),
                steamPoolSize: await this.contracts.getSteamPool(),
                steamwaterPoolSize: await this.contracts.getSteamwaterPool(),
                claimableH2OFromICE:  await this.contracts.getClaimableH2OFromIce(),
                claimableH2OFromSTEAM:  await this.contracts.getClaimableH2OFromSteam(),
                lastError: await this.contracts.getLastError(),
                accumulatedError: await this.contracts.getAccumulatedError()
            });
        } else {
            this.setState({
                iceBalance:   0,
                h2oBalance:   0,
                stmBalance:   0, 
            });
        }
    }

    render() {

        var header;

        // Info about Account and Contract
        if (this.contracts.isConnected()) {
            const accountAddress = this.contracts.getAccountAddress();
            const controllerAddress = this.contracts.getControllerAddress();

            header = <div>
                <div><b>Account:</b> {accountAddress}</div>
                <div><b>Contract:</b> {controllerAddress}</div>
            </div>
        } else {
            header = <div>
                <form onSubmit={this.connectWallet}>
                    <button className="connectButton">Connect&nbsp;Wallet</button>
                </form>
                <div><h3>Not Connected...</h3></div>
            </div>
        }


        // Error Messages
        var error;
        if (this.state.errorMessage) {
            error = <div><h3 className="errorText"><i>Error: {this.state.errorMessage}</i></h3></div>
        }
        
        return (
            <div align="center">               
                <div>
                    <table className='mainTable'>
                        <thead className='mainTableHead'>
                        <tr>
                            <td colSpan="2"> 
                                {header}
                                {error}
                            </td>
                        </tr>    
                        </thead>
                        
                        <tbody>
                        <tr>
                            <td className='swapTableCell'>
                                <SwapForm coinFrom="H2O" coinTo="ICE" contracts={this.contracts} setError={this.setError}/>
                                <SwapForm coinFrom="ICE" coinTo="H2O" contracts={this.contracts} setError={this.setError}/>
                            </td>
                            <td  className='swapTableCell'>
                                <SwapForm coinFrom="H2O" coinTo="STM" contracts={this.contracts} setError={this.setError}/>
                                <SwapForm coinFrom="STM" coinTo="H2O" contracts={this.contracts} setError={this.setError}/>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <form onSubmit={this.onClaimICE}>
                                    <button type="submit" className="swapButton">Claim&nbsp;H2O&nbsp;From&nbsp;ICE</button>
                                </form>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <form onSubmit={this.onClaimSTEAM}>
                                    <button type="submit" className="swapButton">Claim&nbsp;H2O&nbsp;From&nbsp;STM</button>
                                </form>
                            </td>
                        </tr>

                        <tr>
                            <td className="poolsRow"><b>H2O Price:</b></td>
                            <td>{this.state.h2oPrice}</td>
                        </tr>

                        <tr><td colSpan="2"><h3>Ice Parameters:</h3></td></tr>

                        <tr>
                            <td className="poolsRow"><b>Claimable H2O from ICE:</b></td>
                            <td>{this.state.claimableH2OFromICE}</td>
                        </tr>

                        <tr>
                            <td className="poolsRow"><b>ICE Melt Rate:</b></td>
                            <td>1</td>
                        </tr>

                        <tr>
                            <td className="poolsRow"><b>Target ICE Price:</b></td>
                            <td>{this.state.targetIcePrice}</td>
                        </tr>

                        <tr>
                            <td className="poolsRow"><b>ICE Price:</b></td>
                            <td>{this.state.icePrice}</td>
                        </tr>

                        <tr>
                            <td className="poolsRow"><b>Target ICE Yield:</b></td>
                            <td>{100/this.state.targetIcePrice}%</td>
                        </tr>


                        <tr>
                            <td className="poolsRow"><b>ICE Yield:</b></td>
                            <td>{100/this.state.icePrice}%</td>
                        </tr>

                        <tr><td colSpan="2"><h3>Steam Parameters:</h3></td></tr>

                        <tr>
                            <td className="poolsRow"><b>Claimable H2O from STEAM:</b></td>
                            <td>{this.state.claimableH2OFromSTEAM}</td>
                        </tr>

                        <tr>
                            <td className="poolsRow"><b>STEAM Condensation Rate:</b></td>
                            <td>{this.state.steamRate}</td>
                        </tr>

                        <tr>
                            <td className="poolsRow"><b>STEAM Price:</b></td>
                            <td>{this.state.steamPrice}</td>
                        </tr>

                        <tr>
                            <td className="poolsRow"><b>STEAM Yield:</b></td>
                            <td>{this.state.steamRate*100/this.state.steamPrice}%</td>
                        </tr>


                        <tr><td colSpan="2"><h3>Account Balances:</h3></td></tr>
                        <tr>
                            <td className="balancesRow"><b>H2O:</b></td>
                            <td>{this.state.h2oBalance}</td>
                        </tr>
                        <tr>
                            <td className="balancesRow"><b>ICE:</b></td>
                            <td>{this.state.iceBalance}</td>
                        </tr>
                        <tr>
                            <td className="balancesRow"><b>STEAM:</b></td>
                            <td>{this.state.stmBalance}</td>
                        </tr>

                        <tr><td colSpan="2"><h3>Pools:</h3></td></tr>

                        <tr>
                            <td className="balancesRow"><b>Ice Pool:</b></td>
                            <td>{this.state.icePoolSize}</td>
                        </tr>

                        <tr>
                            <td className="balancesRow"><b>Icewater Pool:</b></td>
                            <td>{this.state.icewaterPoolSize}</td>
                        </tr>

                        <tr>
                        <td className="balancesRow"><b>Steam Pool:</b></td>
                            <td>{this.state.steamPoolSize}</td>
                        </tr>

                        <tr>
                            <td className="balancesRow"><b>Steamwater Pool:</b></td>
                            <td>{this.state.steamwaterPoolSize}</td>
                        </tr>

                        <tr><td colSpan="2"><h3>Error Terms:</h3></td></tr>

                        <tr>
                            <td className="balancesRow"><b>Last Error:</b></td>
                            <td>{this.state.lastError}</td>
                        </tr>

                        <tr>
                            <td className="balancesRow"><b>Accumulated Error:</b></td>
                            <td>{this.state.accumulatedError}</td>
                        </tr>

                        </tbody>
                    </table>
                </div>        
                <br></br>
            </div>  
        );
    }

}

export default App;
