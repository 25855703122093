import ControllerJson from '../abis/Controller.json'
import IceTokenJson from '../abis/IceToken.json'
import H2OTokenJson from '../abis/H2OToken.json'
import SteamTokenJson from '../abis/SteamToken.json'
import Web3 from 'web3'

// Wraps the necessary H2O smart contracts.

class Contracts 
{
    constructor(setError) 
    {
        this.Controller = null;
        this.IceToken = null;
        this.H2OToken = null;
        this.SteamToken = null;
        
        this.controllerAddress = null;
        this.account = null;

        this.setError = setError;
    }

    isConnected() {
        return this.Controller && this.IceToken && this.H2OToken && this.SteamToken && this.account;
    }

    async initWeb3() {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
            await window.ethereum.enable();
        }
        else if (window.web3) {
            window.web3 = new Web3(window.web3.currentProvider);
        }
        else {
            this.setError('Non-Ethereum browser detected. You should consider trying MetaMask!');
            return false;
        }

        return true;
    }

    async load() {
        try {
            const web3 = window.web3;
            if (!web3) {
                //todo
                return;
            }
            
            const networkId = await web3.eth.net.getId();
            
            // Get the current account
            const accounts = await web3.eth.getAccounts();
            this.account = accounts[0];
        
            // Load the Controller contract
            const controllerData = ControllerJson.networks[networkId];
            if(controllerData) {
                this.Controller = new web3.eth.Contract(ControllerJson.abi, controllerData.address);
                this.controllerAddress = controllerData.address;
            } else {
                this.setError('Controller contract not deployed to this network.');
                return;
            }

            // Load the IceToken contract
            const iceTokenData = IceTokenJson.networks[networkId];
            if(iceTokenData) {
                this.IceToken = new web3.eth.Contract(IceTokenJson.abi, iceTokenData.address);
            } else {
                this.setError('IceToken contract not deployed to this network.');
                return;
            }

            // Load the H2OToken contract
            const h2oTokenData = H2OTokenJson.networks[networkId];
            if(h2oTokenData) {
                this.H2OToken = new web3.eth.Contract(H2OTokenJson.abi, h2oTokenData.address);
            } else {
                this.setError('H2OToken contract not deployed to this network.');
                return;
            }

            // Load the IceToken contract
            const steamTokenData = SteamTokenJson.networks[networkId];
            if(steamTokenData) {
                this.SteamToken = new web3.eth.Contract(SteamTokenJson.abi, steamTokenData.address);
            } else {
                this.setError('SteamToken contract not deployed to this network.');
                return;
            }
       
        } catch (err) {
            this.account = null;
            this.Controller = null;

            this.setError(err);
        }
    }

    registerSwapEventHandler(onSwapHandler) {
        // Subscribe to events
        this.Controller.events.Swap(
           { fromBlock: 'latest', filter: {account: this.account} },
           onSwapHandler);
   } 

    getShortAddress(addr) {
        if (!addr) {
            return addr;
        }
        return addr.slice(0, 8) + "..." + addr.slice(-4);
    }

    getAccountAddress() {
        return this.account;
    }

    getControllerAddress() {
        if (this.Controller && this.controllerAddress) {
            return this.controllerAddress;
        }

        return null;
    }

    async getCondensationRate() {
        return Web3.utils.fromWei(
            await this.Controller.methods.getCondensationRate().call());
    }

    async getTargetIcePrice() {
        return Web3.utils.fromWei(
            await this.Controller.methods.getTargetIcePrice().call());
    }

    async getICETotalSupply() {
        return Web3.utils.fromWei(
            await this.IceToken.methods.totalSupply().call());
    }

    async getH2OTotalSupply() {
        return Web3.utils.fromWei(
            await this.H2OToken.methods.totalSupply().call());
    }

    async getSTEAMTotalSupply() {
        return Web3.utils.fromWei(
            await this.SteamToken.methods.totalSupply().call());
    }

    async getH2OPrice() {
        return Web3.utils.fromWei(
            await this.Controller.methods.getH2OPrice().call());
    }

    async getIcePrice() {
        return Web3.utils.fromWei(
            await this.Controller.methods.getIcePrice().call());
    }

    async getSteamPrice() {
        return Web3.utils.fromWei(
            await this.Controller.methods.getSteamPrice().call());
    }

    async getIcePool() {
        return Web3.utils.fromWei(
            await this.Controller.methods.getIcePool().call());
    }

    async getIcewaterPool() {
        return Web3.utils.fromWei(
            await this.Controller.methods.getIcewaterPool().call());
    }

    async getSteamPool() {
        return Web3.utils.fromWei(
            await this.Controller.methods.getSteamPool().call());
    }

    async getSteamwaterPool() {
        return Web3.utils.fromWei(
            await this.Controller.methods.getSteamwaterPool().call());
    }

    async getLastError() {
        return Web3.utils.fromWei(
            await this.Controller.methods.getLastError().call());
    }

    async getAccumulatedError() {
        return Web3.utils.fromWei(
            await this.Controller.methods.getAccumulatedError().call());
    }

    async getClaimableH2OFromIce() {
        return Web3.utils.fromWei(
            await this.Controller.methods.getClaimableH2OFromIce().call({ from: this.account }));
    }

    async getClaimableH2OFromSteam() {
        return Web3.utils.fromWei(
            await this.Controller.methods.getClaimableH2OFromSteam().call({ from: this.account }));
    }

    async claimH2OFromICE() {
        this.Controller.methods.claimH2OFromIce()
            .send({ from: this.account })
                .on('transactionHash', (hash) => {
                    console.log("claim melt")
                });
    }

    async claimH2OFromSteam() {
        this.Controller.methods.claimH2OFromSteam()
            .send({ from: this.account })
                .on('transactionHash', (hash) => {
                    console.log("claim melt")
                });
    }

    async getICEBalance(account) {
        return Web3.utils.fromWei(
            await this.IceToken.methods.balanceOf(account).call());
    }

    async getH2OBalance(account) {
        return Web3.utils.fromWei(
            await this.H2OToken.methods.balanceOf(account).call());
    }

    async getSTEAMBalance(account) {
        return Web3.utils.fromWei(
            await this.SteamToken.methods.balanceOf(account).call());
    }

    async previewSwapICEForH2O(amount) {
        const weiAmount = Web3.utils.toWei(amount);
        const result = await this.Controller.methods.previewSwapIceForWater(weiAmount).call();
        return Web3.utils.fromWei(result);
    }

    async swapICEForH2O(amount) {
        this.Controller.methods.swapIceForWater(Web3.utils.toWei(amount))
            .send({ from: this.account })
                .on('transactionHash', (hash) => { 
                    console.log("Swapped ICE->H2O:", amount.toString())
                });
    }

    async previewSwapH2OForICE(amount) {
        const weiAmount = Web3.utils.toWei(amount);
        const result = await this.Controller.methods.previewSwapWaterForIce(weiAmount).call();
        return Web3.utils.fromWei(result);
    }

    async swapH2OForICE(amount) {
        this.Controller.methods.swapWaterForIce(Web3.utils.toWei(amount))
            .send({ from: this.account })
                .on('transactionHash', (hash) => { 
                    console.log("Swapped H2O->ICE:", amount.toString())
                });
    }

    async previewSwapSTMForH2O(amount) {
        const weiAmount = Web3.utils.toWei(amount);
        const result = await this.Controller.methods.previewSwapSteamForWater(weiAmount).call();
        return Web3.utils.fromWei(result);
    }

    async swapSTMForH2O(amount) {
        this.Controller.methods.swapSteamForWater(Web3.utils.toWei(amount))
            .send({ from: this.account })
                .on('transactionHash', (hash) => { 
                    console.log("Swapped STEAM->H2O:", amount.toString())
                });
    }

    async previewSwapH2OForSTM(amount) {
        const weiAmount = Web3.utils.toWei(amount);
        const result = await this.Controller.methods.previewSwapWaterForSteam(weiAmount).call();
        return Web3.utils.fromWei(result);
    }

    async swapH2OForSTM(amount) {
        this.Controller.methods.swapWaterForSteam(Web3.utils.toWei(amount))
            .send({ from: this.account })
                .on('transactionHash', (hash) => { 
                    console.log("Swapped H2O->STEAM:", amount.toString())
                });
    }
  
}

export default Contracts;